.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 90px;
  padding-bottom: 50px;
  margin: var(--container-spacing);
}

.skills-title {
  display: flex;
  margin: var(--content-spacing);
  margin-left: 15vw;
  margin-right: auto;
}

.skills .hero-container {
  background-image: none;
  border: 1px solid var(--white);
  backdrop-filter: blur( 150px );
  -webkit-backdrop-filter: blur( 150px );
  padding: 10px;
  padding-top: 0;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.skills .hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px;
  margin-top: 0;
  align-self: flex-start;
}

.tab {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 10px 5px 10px 5px;
  align-self: flex-start;
}

.tab button {
  font-weight: bold;
  float: left;
  cursor: pointer;
  padding: 5px 10px;
  margin: 5px;
  transition: 0.3s;
  background: none;
  color: var(--white);
  border: 1px solid transparent;
  border-radius: 5px;
}

.tab button:hover {
  border: 1px solid var(--white);
  box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.2);
}

.tab button.active {
  border: 1px solid var(--white);
  box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.2);
  background: var(--comp-background);
}

.divider {
  width: 100%;
  border-top: 1px solid var(--white);
}

.tabcontent {
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
}

.tabcontent {
  animation: fadeEffect 1.5s;
}

@keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}

.list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 0;
}

.list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.list-item h3 {
  max-width: 15vw;
}

.list-item p {
  margin-left: 5px;
}

.item-divider {
  height: 15px;
  margin: 0 10px 0 10px;
  border-left: 2px solid var(--white);
}
