.about {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
  padding-bottom: 50px;
  margin: var(--container-spacing);
  overflow-x: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-profile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-portrait {
  display: none;
  width: 30vw;
  margin: var(--content-spacing);
  border-radius: 50%;
  border: 3px solid var(--white);
}

.about-title {
  margin: var(--content-spacing);
}

.console {
  z-index: -1;
  overflow: hidden;
  margin: var(--content-spacing);
}

.fakeButtons {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid #000;
  position: relative;
  top: 6px;
  left: 6px;
  background-color: #ff3b47;
  border-color: #9d252b;
  display: inline-block;
}

.fakeMinimize {
  left: 11px;
  background-color: #ffc100;
  border-color: #9d802c;
}

.fakeZoom {
  left: 16px;
  background-color: #00d742;
  border-color: #049931;
}

.fakeMenu {
  display: flex;
  width: 700px;
  box-sizing: border-box;
  height: 25px;
  background-color: #bbb;
  margin: 0 auto;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.fakeScreen {
  display: flex;
  flex-direction: column;
  background: rgba( 27, 29, 33, 0.45 );
  backdrop-filter: blur( 20px );
  -webkit-backdrop-filter: blur( 20px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );

  box-sizing: border-box;
  width: 700px;
  margin: 0 auto;
  padding: var(--content-spacing);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.line1 {
  color: var(--tertiary);
}

.line2 {
  color: var(--white);
}

.line3 {
  color: var(--primary);
}

.line4 {
  color: #fff;
  -webkit-animation: type .5s 10.75s steps(20, end) forwards;
  -moz-animation: type .5s 10.75s steps(20, end) forwards;
  -o-animation: type .5s 10.75s steps(20, end) forwards;
  animation: type .5s 10.75s steps(20, end) forwards;
}

.cursor4 {
  -webkit-animation: blink 1s 0s infinite;
  -moz-animation: blink 1s 0s infinite;
  -o-animation: blink 1s 0s infinite;
  animation: blink 1s 0s infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes blink {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes type {
  to {
    width: 17em;
  }
}

@-moz-keyframes type {
  to {
    width: 17em;
  }
}

@-o-keyframes type {
  to {
    width: 17em;
  }
}

@keyframes type {
  to {
    width: 17em;
  }
}

@media screen and (max-width: 768px) {
  .about {
    padding-top: 150px;
  }

  .about-portrait {
    display: initial;
  }

  .fakeScreen {
    width: 500px;
  }

  .fakeMenu {
    width: 500px;
  }
}

@media screen and (max-width: 480px) {
  .fakeScreen {
    width: 300px;
  }

  .fakeMenu {
    width: 300px;
  }
}
