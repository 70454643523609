.work {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 150px;
  padding-bottom: 50px;
  margin: var(--container-spacing);
  overflow-x: hidden;
}

.work-title {
  margin: var(--content-spacing);
}

.work .hero-container {
  background-image: none;
  border: 1px solid var(--white);
  border-radius: 20px 0 0 20px;
  margin-right: -1px;
  backdrop-filter: blur( 150px );
  -webkit-backdrop-filter: blur( 150px );
  min-height: 500px;
  height: auto;
}

.work .hero-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin: 10px;
  padding: 10px;
}

.work-divider {
  height: 50vh;
  width: 0;
  border-left: 1px solid var(--white);
  margin: 20px;
  align-self: center;
}

.company {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.company-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.company-logo img {
  width: 15vw;
  border-radius: 20%;
}

.team {
  font-style: italic;
  margin: 0;
}

.work-description {
  text-align: center;
  margin: 10px;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .work {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
  }

  .work .hero-container {
    border-radius: 20px;
    margin-right: 0px;
  }

  .work .hero-content {
    flex-direction: column;
  }

  .work-divider {
    height: 0px;
    width: 50vw;
    border-left: none;
    border-top: 1px solid var(--white);
    align-self: center;
  }
}
