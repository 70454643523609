.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  margin: var(--container-spacing);
  margin-bottom: 0;
  background-image: url(../assets/swirl-2.svg);
}

.projects-title {
  margin: var(--content-spacing);
}

.projects-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.projects .hero-container {
  display: flex;
  justify-content: center;
  align-content: center;
  height: auto;
  width: auto;
  max-width: 25vw;
  margin: 20px 20px;
  padding: 10px 10px;
  background-image: none;
  border: 1px solid var(--white);
  backdrop-filter: blur( 150px );
  -webkit-backdrop-filter: blur( 150px );
}

.projects .hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: 10px 10px;
}

.title-tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.title-tag-divider {
  height: 3vh;
  border-left: 2px solid var(--white);
  align-self: center;
  margin-left: 10px;
  margin-right: 10px;
}

.project-type {
  color: var(--primary);
}

.project-img {
  width: 50%;
  border-radius: 20%;
  border: 3px solid var(--white);
  align-self: center;
  background-color: var(--comp-background);
}

.project-tags {
  margin: 0;
  margin-top: 10px;
  font-weight: bold;
}

.project-description {
  margin: 0;
  margin-top: 5px;
}

.project-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin: 0;
  padding: 0;
}

.project-links a {
  color: var(--white);
  text-align: center;
  text-decoration: none;
  padding-top: 5px;
  margin: 10px 10px;
  transition: color 1s;
}

.no-link {
  color: var(--white);
  text-align: center;
  padding-top: 5px;
  margin: 10px 10px;
}

.project-links a:hover {
  color: var(--tertiary);
  transition: border 1s;
}

@media screen and (max-width: 768px) {
  .projects .hero-container {
    height: auto;
    width: auto;
    max-width: 50vw;
    margin: 20px 20px;
    padding: var(--content-spacing);
    background-image: none;
    border: 1px solid var(--white);
    backdrop-filter: blur( 150px );
    -webkit-backdrop-filter: blur( 150px );
  }

  .projects {
    background-image: url(../assets/swirl-2.svg), url(../assets/swirl-3.svg);
    background-position: 60% top, 30% bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: 300%, 300%;
  }
}

@media screen and (max-width: 480px) {
  .projects .hero-container {
    height: auto;
    width: auto;
    max-width: 50vw;
    margin: 20px 20px;
    padding: var(--content-spacing);
    background-image: none;
    border: 1px solid var(--white);
    backdrop-filter: blur( 150px );
    -webkit-backdrop-filter: blur( 150px );
  }

  .projects {
    background-image: url(../assets/swirl-2.svg), url(../assets/swirl-3.svg);
    background-position: 50% top, 70% 80%;
    background-repeat: no-repeat, no-repeat;
    background-size: 400%, 400%;
  }
}
