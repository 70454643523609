body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--white);
  background: var(--background);
  font-size: 16px;
  background-image: url(assets/swirl.svg);
  background-position: 10% 28%;
  background-repeat: no-repeat;
  background-size: 200%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
    background-position: 50% 23%;
    background-repeat: no-repeat;
    background-size: 300%;
  }
}

@media screen and (max-width: 480px) {
  body {
    font-size: 12px;
    background-position: 50% 23%;
    background-repeat: no-repeat;
    background-size: 700%;
  }
}
